import { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from '../Store';
import {
  addCustomer,
  addUser,
  getUser,
  updateUser,
  updateUserCustomFields,
  updateUserCustomProperties,
} from '../backend/customers';

import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PageHeader from '../Components/pageHeader';
import Button from 'react-bootstrap/Button';
// import {
//   updateUserName,
//   updateDeviceInfo,
//   updatePhoneNo,
// } from '../backend/users';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_REQUEST':
      return { ...state, loading: true };
    case 'ADD_SUCCESS':
      return { ...state, loading: false };
    case 'ADD_FAIL':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default function AddCustomerPage() {
  const navigate = useNavigate();

  const { state } = useContext(Store);
  const { userInfo } = state;

  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: false,
    error: '',
  });

  const [customerId, setCustomerId] = useState('');
  const [name, setName] = useState('');
  // const [status, setStatus] = useState('');
  // const [balance, setBalance] = useState(0);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'ADD_REQUEST' });
      const newCustomer = {};
      newCustomer.customerId = customerId;
      newCustomer.name = name;
      //newCustomer.status = status;
      //newCustomer.plan = plan;
      //newCustomer.balance = balance;
      await addCustomer(userInfo.userId, newCustomer);
      dispatch({ type: 'ADD_SUCCESS' });
      toast.success('Customer added successfully.');
      navigate(`/customers`);
    } catch (e) {
      dispatch({ type: 'ADD_FAIL' });
      toast.error(
        `${e.fullError.reason}.\n
        Add failed.\n Try again.\n`
      );
    }
  };

  return (
    <Container className="small-container">
      <Card>
        <Card.Body>
          <Form onSubmit={submitHandler}>
            <Row>
              {' '}
              <Col>
                <h5>Add Customer</h5>
              </Col>
              <Col>
                <Button className="float-end" disabled={loading} type="submit">
                  Add
                </Button>
                {loading && <div>Loading...</div>}
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="customerId">
              <Form.Label>Customer Id</Form.Label>
              <Form.Control
                value={customerId}
                onChange={(e) => {
                  setCustomerId(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
            {/* <Form.Group className="mb-3" controlId="status">
              <Form.Label>Status</Form.Label>
              <Form.Control
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group> */}
            {/* <Form.Group className="mb-3" controlId="plan">
              <Form.Label>Plan</Form.Label>
              <Form.Control
                value={plan}
                onChange={(e) => {
                  setPlan(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group> */}
            {/* <Form.Group className="mb-3" controlId="balance">
              <Form.Label>Balance</Form.Label>
              <Form.Control
                value={balance}
                onChange={(e) => {
                  setBalance(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group> */}
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}
