export async function tokenHandler(userInfo) {
  console.log('HIER tokenHandler');

  wialon.core.Session.getInstance().initSession('https://hst-api.wialon.com');
  return await new Promise((resolve, reject) => {
    wialon.core.Session.getInstance().loginToken(
      userInfo.tId,
      '',
      async (error) => {
        if (error) {
          console.log(
            `Error: ${error} - ${wialon.core.Errors.getErrorText(error)}`
          );
          console.log('HIER tokenHandler fout');
          reject(error);
        }
        console.log('HIER tokenHandler INIT');
        const session = wialon.core.Session.getInstance();
        userInfo.userId = session.getCurrUser().getId();
        userInfo.user = session.getCurrUser().getName();
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
        console.log('userInfo storing', JSON.stringify(userInfo));

        resolve(true);
      }
    );
  });
}

export async function getResource(resourceId) {
  try {
    const session = wialon.core.Session.getInstance();
    const flags =
      wialon.item.Item.dataFlag.base | wialon.item.Item.dataFlag.billingProps;
    session.loadLibrary('resourceAccounts');
    return await new Promise((resolve, reject) => {
      session.updateDataFlags(
        [{ type: 'type', data: 'avl_resource', flags: flags, mode: 1 }],
        (error) => {
          if (error) {
            console.log(
              'getResource - API ERROR ' +
                error +
                ' #' +
                wialon.core.Errors.getErrorText(error)
            );
            reject({ type: 'API_ERROR', error: error });
            //return;
          }
          const resources = session.getItems('avl_resource');
          if (!resources || !resources.length) {
            console.log('getResource - No resources found');
            reject({ type: 'DATA_ERROR', error: 'No resources found' });
            //return;
          }

          for (let i = 0; i < resources.length; i++) {
            const resource = {};
            if (parseInt(resources[i].getId()) === parseInt(resourceId)) {
              resource.id = resourceId;
              resource.name = resources[i].getName();
              const wResource =
                wialon.core.Session.getInstance().getItem(resourceId);
              const isAccount =
                parseInt(resourceId) === parseInt(wResource.getAccountId());
              if (isAccount) {
                resource.isAccount = true;
                wResource.getAccountData(function (err, data) {
                  if (err) {
                    console.log(wialon.core.Errors.getErrorText(err));
                    return;
                  }
                  resource.accountStatus = data.enabled ? 'Enabled' : 'BLOCKED';
                  resource.accountPlan = data.plan;
                  resource.accountBalance = data.balance;

                  resolve(resource);
                });
              } else {
                resource.isAccount = false;
                resolve(resource);
              }
            }
          }
        }
      );
    });
  } catch (e) {
    console.error('tyretrackJS: getResource - ERROR ', e);
  }
}

export async function manageAccount(resourceId, isEnabled) {
  try {
    return await new Promise((resolve, reject) => {
      const wResource = wialon.core.Session.getInstance().getItem(resourceId);

      wResource.enableAccount(isEnabled, function (error) {
        if (error) {
          console.log(wialon.core.Errors.getErrorText(error));
          reject({ type: 'API_ERROR', error: error });
        } else {
          resolve();
        }
      });

      // session.updateDataFlags(
      //   [{ type: 'type', data: 'avl_resource', flags: flags, mode: 1 }],
      //   (error) => {
      //     if (error) {
      //       console.log(
      //         'manageAccount - API ERROR ' +
      //           error +
      //           ' #' +
      //           wialon.core.Errors.getErrorText(error)
      //       );
      //       reject({ type: 'API_ERROR', error: error });
      //       //return;
      //     }
      //     const resources = session.getItems('avl_resource');
      //     if (!resources || !resources.length) {
      //       console.log('getResource - No resources found');
      //       reject({ type: 'DATA_ERROR', error: 'No resources found' });
      //       //return;
      //     }

      //     for (let i = 0; i < resources.length; i++) {
      //       const resource = {};
      //       if (parseInt(resources[i].getId()) === parseInt(resourceId)) {

      //         const wResource =
      //           wialon.core.Session.getInstance().getItem(resourceId);
      //         const isAccount =
      //           parseInt(resourceId) === parseInt(wResource.getAccountId());
      //         if (isAccount) {
      //           resource.isAccount = true;
      //           wResource.getAccountData(function (err, data) {
      //             if (err) {
      //               console.log(wialon.core.Errors.getErrorText(err));
      //               return;
      //             }
      //             resource.accountStatus = data.enabled ? 'Enabled' : 'BLOCKED';
      //             resource.accountPlan = data.plan;
      //             resource.accountBalance = data.balance;

      //             resolve(resource);
      //           });
      //         } else {
      //           resource.isAccount = false;
      //           resolve(resource);
      //         }
      //       }
      //     }
      //   }
      // );
    });
  } catch (e) {
    console.error('manageAccount - ERROR ', e);
    return wialon.core.Errors.getErrorText(e.error);
  }
}

export async function updateBillingPlan(resourceId, billingPlan) {
  try {
    return await new Promise((resolve, reject) => {
      const wResource = wialon.core.Session.getInstance().getItem(resourceId);

      wResource.updatePlan(billingPlan, function (error) {
        if (error) {
          console.log(wialon.core.Errors.getErrorText(error));
          reject({ type: 'API_ERROR', error: error });
        } else {
          resolve();
        }
      });
    });
  } catch (e) {
    console.error('updateBillingPlan - ERROR ', e);
    return wialon.core.Errors.getErrorText(e.error);
  }
}

export async function getResources(userInfo) {
  try {
    const session = wialon.core.Session.getInstance();
    const flags =
      wialon.item.Item.dataFlag.base | wialon.item.Item.dataFlag.billingProps;
    session.loadLibrary('resourceAccounts');
    return await new Promise(async (resolve) => {
      session.updateDataFlags(
        [{ type: 'type', data: 'avl_resource', flags: flags, mode: 1 }],
        async (error) => {
          if (error) {
            console.log(
              'getResources - API ERROR ' +
                error +
                ' #' +
                wialon.core.Errors.getErrorText(error)
            );
          }

          const resources = session.getItems('avl_resource');
          if (!resources || !resources.length) {
            console.log('tyretrackJS: getResource - No resources found');
          }
          console.log('resources!!!');
          console.log(resources);
          const resourceData = [];
          for (let i = 0; i < resources.length; i++) {
            const resourceId = resources[i].getId();
            //const resourceName = resources[i].getName();
            if (resourceId !== 21973104) {
              //pressuretracksa userId:21973103 accountid 21973104

              const resource = {};

              ////??
              resource.id = resourceId;
              resource.name = resources[i].getName();
              const user = await getAccountMainUser(resourceId);
              console.log('user');
              console.log(resourceId);
              console.log(user);
              if (user.items.length) {
                resource.userId = user.items[0].id;
                resource.ptId = user.items[0].nm;
              } else {
                resource.userId = resources[i].getCreatorId();
                resource.ptId = userInfo.user;
                console.log('user.items[0].nm');
              }

              const wResource =
                wialon.core.Session.getInstance().getItem(resourceId);
              const isAccount =
                parseInt(resourceId) === parseInt(wResource.getAccountId());
              if (isAccount) {
                resource.isAccount = true;
                wResource.getAccountData(function (err, data) {
                  if (err) {
                    console.log(wialon.core.Errors.getErrorText(err));
                    return;
                  }
                  resource.accountStatus = data.enabled ? 'Enabled' : 'BLOCKED';
                  resource.accountPlan = data.plan;
                  resource.accountBalance = data.balance;

                  //resolve(resource);
                });
                resourceData.push(resource);
              }
            }
          }
          console.log('resourceData');
          console.log(resourceData);
          resolve(resourceData);
        }
      );
    });
  } catch (e) {
    console.error('tyretrackJS: getResource - ERROR ', e);
  }
}

// async function getResource(resourceId) {
//   try {
//     const session = wialon.core.Session.getInstance();
//     const flags =
//       wialon.item.Item.dataFlag.base | wialon.item.Item.dataFlag.billingProps;
//     session.loadLibrary('resourceAccounts');
//     return await new Promise((resolve, reject) => {
//       session.updateDataFlags(
//         [{ type: 'type', data: 'avl_resource', flags: flags, mode: 1 }],
//         (error) => {
//           if (error) {
//             console.log(
//               'getResource - API ERROR ' +
//                 error +
//                 ' #' +
//                 wialon.core.Errors.getErrorText(error)
//             );
//             reject({ type: 'API_ERROR', error: error });
//             return;
//           }
//           const resources = session.getItems('avl_resource');
//           if (!resources || !resources.length) {
//             console.log('getResource - No resources found');
//             return;
//           }

//           for (let i = 0; i < resources.length; i++) {
//             const resource = {};
//             if (parseInt(resources[i].getId()) === parseInt(resourceId)) {
//               resource.id = resourceId;
//               resource.name = resources[i].getName();
//               const wResource =
//                 wialon.core.Session.getInstance().getItem(resourceId);
//               const isAccount =
//                 parseInt(resourceId) === parseInt(wResource.getAccountId());
//               if (isAccount) {
//                 resource.isAccount = true;
//                 wResource.getAccountData(function (err, data) {
//                   if (err) {
//                     console.log(wialon.core.Errors.getErrorText(err));
//                     return;
//                   }
//                   console.log(`kom hier uit`);
//                   console.log(data);
//                   resource.accountStatus = data.enabled ? 'Enabled' : 'BLOCKED';
//                   resource.accountPlan = data.plan;
//                   resource.accountBalance = data.balance;

//                   resolve(resource);
//                 });
//               } else {
//                 resource.isAccount = false;
//                 resolve(resource);
//               }
//             }
//           }
//         }
//       );
//     });
//   } catch (e) {
//     console.error('tyretrackJS: getResource - ERROR ', e);
//   }
// }

export const getAccountMainUser = async (resourceId) => {
  try {
    const remote = wialon.core.Remote.getInstance();
    const prms = {
      spec: {
        itemsType: 'user',
        propName: 'sys_user_creator,sys_billing_account_guid',
        propValueMask: `21973103,${resourceId}`,
        sortType: 'sys_id',
      },
      force: 1,
      flags: -1,
      from: 0,
      to: 0,
    };

    return new Promise((resolve, reject) => {
      remote.remoteCall('core/search_items', prms, async (error, result) => {
        if (error) {
          console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
          reject({ type: 'API_ERROR', code: error, fullError: result });
        }
        resolve(result);
      });
    });
  } catch (e) {
    console.error('tyretrackJS: getUnit - ERROR ', e);
  }
};

export const getUsers = async (userInfo) => {
  try {
    const remote = wialon.core.Remote.getInstance();
    const prms = {
      spec: {
        itemsType: 'user',
        propName: 'sys_billing_account_guid',
        propValueMask: `${userInfo.aId}`,
        sortType: 'sys_id',
      },
      force: 1,
      flags: -1,
      from: 0,
      to: 0,
    };

    return new Promise((resolve, reject) => {
      remote.remoteCall('core/search_items', prms, async (error, result) => {
        if (error) {
          console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
          reject({ type: 'API_ERROR', code: error, fullError: result });
        }
        console.log('users result');
        console.log(result);
        const users = [];

        if (result.items.length) {
          for (let u = 0; u < result.items.length; u++) {
            const user = {};
            const userId = result.items[u].id;
            if (userId !== userInfo.uId) {
              user.userId = userId;
              user.ptId = result.items[u].nm;
              user.name = '';
              user.surname = '';
              user.contactNo = '';
              user.emailAddress = result.items[u].prp.email;
              user.role = '';

              const userDetail = [];

              const infoFlds = result.items[u].flds;

              Object.keys(infoFlds).forEach((key) =>
                userDetail.push({ info: infoFlds[key] })
              );

              for (let ui = 0; ui < userDetail.length; ui++) {
                if (userDetail[ui].info.n === 'Name')
                  user.name = userDetail[ui].info.v;
                if (userDetail[ui].info.n === 'Surname')
                  user.surname = userDetail[ui].info.v;
                if (userDetail[ui].info.n === 'ContactNo')
                  user.contactNo = userDetail[ui].info.v;
                if (userDetail[ui].info.n === 'Role')
                  user.role = userDetail[ui].info.v;
              }

              users.push(user);
            }
          }
        }
        resolve(users);
      });
    });
  } catch (e) {
    console.error('getUsers - ERROR ', e);
  }
};

export const getUser = async (userId) => {
  try {
    const remote = wialon.core.Remote.getInstance();
    const prms = {
      spec: {
        itemsType: 'user',
        propName: 'sys_id',
        propValueMask: `${userId}`,
        sortType: 'sys_id',
      },
      force: 1,
      flags: -1,
      from: 0,
      to: 0,
    };

    console.log('USER prms');
    console.log(prms);

    return new Promise((resolve, reject) => {
      remote.remoteCall('core/search_items', prms, async (error, result) => {
        if (error) {
          console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
          reject({ type: 'API_ERROR', code: error, fullError: result });
        }
        console.log('USER result');
        console.log(result);

        if (result.items.length) {
          const user = {};
          user.userId = result.items[0].id;
          user.ptId = result.items[0].nm;
          user.nameFieldId = 0;
          user.name = '';
          user.surname = '';
          user.contactNo = '';
          user.email = result.items[0].prp.email;
          user.role = '';

          const userInfo = [];

          const infoFlds = result.items[0].flds;

          Object.keys(infoFlds).forEach((key) =>
            userInfo.push({ info: infoFlds[key] })
          );

          for (let ui = 0; ui < userInfo.length; ui++) {
            if (userInfo[ui].info.n === 'Name') {
              user.nameFieldId = userInfo[ui].info.id;
              user.name = userInfo[ui].info.v;
            }
            if (userInfo[ui].info.n === 'Surname') {
              user.surnameFieldId = userInfo[ui].info.id;
              user.surname = userInfo[ui].info.v;
            }
            if (userInfo[ui].info.n === 'ContactNo') {
              user.contactNoFieldId = userInfo[ui].info.id;
              user.contactNo = userInfo[ui].info.v;
            }
            if (userInfo[ui].info.n === 'Role') {
              user.roleFieldId = userInfo[ui].info.id;
              user.role = userInfo[ui].info.v;
            }
          }

          resolve(user);
        }
      });
    });
  } catch (e) {
    console.error('getUsers - ERROR ', e);
  }
};

export async function addCustomer(userId, customer) {
  try {
    let prms = {
      creatorId: userId,
      name: customer.customerId,
      password: `${customer.customerId + '@pT!'}`,
      dataFlags: 1,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('core/create_user', prms, async (error, result) => {
        if (error) {
          console.error('addCustomer - ERROR ', result);
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }

        console.log('new cust 1');
        console.log(result);
        await addCustomerResource(result.item.id, customer);

        // createUserCustomFields(result.item.id, 0, 'Name', customer.name);
        // createUserCustomFields(result.item.id, 0, 'Surname', customer.surname);

        resolve(result);
      });
    });
  } catch (e) {
    console.error('addUser - ERROR ', e);
  }
}

export async function addCustomerResource(creatorId, customer) {
  //600268460
  try {
    let prms = {
      creatorId: creatorId,
      name: customer.name,
      dataFlags: -1,
      skipCreatorCheck: 0,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('core/create_resource', prms, async (error, result) => {
        if (error) {
          console.error('addCustomerResource - ERROR ', result);
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }

        console.log('new cust 2');
        console.log(result);
        await addCustomerAccount(result.item.id);
        // createUserCustomFields(result.item.id, 0, 'Name', customer.name);
        // createUserCustomFields(result.item.id, 0, 'Surname', customer.surname);

        resolve(result);
      });
    });
  } catch (e) {
    console.error('addResource - ERROR ', e);
  }
}

export async function addCustomerAccount(resourceId) {
  console.log('useresourceIdIdrId');
  console.log(resourceId);
  //600268460
  try {
    let prms = {
      itemId: resourceId,
      plan: 'pressuretrackglobal',
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('account/create_account', prms, (error, result) => {
        if (error) {
          console.error('addCustomerAccount - ERROR ', result);
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }

        console.log('new cust 3');
        console.log(result);
        // createUserCustomFields(result.item.id, 0, 'Name', customer.name);
        // createUserCustomFields(result.item.id, 0, 'Surname', customer.surname);

        resolve(result);
      });
    });
  } catch (e) {
    console.error('addAccount - ERROR ', e);
  }
}

export async function addUser(userId, newUser) {
  try {
    let prms = {
      creatorId: userId,
      name: newUser.user,
      password: `${newUser.user + '@0' + newUser.contactNo.slice(3)}`,
      dataFlags: 1,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('core/create_user', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }

        createUserCustomFields(result.item.id, 0, 'Name', newUser.name);
        createUserCustomFields(result.item.id, 0, 'Surname', newUser.surname);
        createUserCustomFields(
          result.item.id,
          0,
          'ContactNo',
          newUser.contactNo
        );
        updateUserCustomProperties(result.item.id, 'email', newUser.email);
        createUserCustomFields(result.item.id, 0, 'Role', newUser.role);

        resolve(result);
      });
    });
  } catch (e) {
    console.error('addUser - ERROR ', e);
  }
}

export async function updateUser(userId, user) {
  try {
    let prms = {
      itemId: userId,
      name: user,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('item/update_name', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        console.log('newname USER');
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('updateUserName - ERROR ', e);
  }
}

export async function updateUserCustomProperties(
  userId,
  propertyName,
  propertyValue
) {
  try {
    console.log('createUserCustomProp(userId)');
    console.log(userId);
    let prms = {
      itemId: userId,
      name: propertyName,
      value: propertyValue,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall(
        'item/update_custom_property',
        prms,
        (error, result) => {
          if (error) {
            reject({ type: 'API_ERROR', code: error, fullError: result });
            return;
          }
          console.log('NEW PPROP USER');
          console.log(result);
          resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
        }
      );
    });
  } catch (e) {
    console.error('updateUserName - ERROR ', e);
  }
}

export async function createUserCustomFields(
  userId,
  fieldId,
  field,
  fieldValue
) {
  try {
    let prms = {
      itemId: userId,
      id: fieldId,
      callMode: 'create',
      n: field,
      v: fieldValue,
    };

    console.log('prems');
    console.log(prms);
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('item/update_custom_field', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        console.log('newname USER');
        console.log(result);
        resolve(result);
      });
    });
  } catch (e) {
    console.error('updateUserName - ERROR ', e);
  }
}

export async function updateUserCustomFields(
  userId,
  fieldId,
  field,
  fieldValue
) {
  try {
    let prms = {
      itemId: userId,
      id: fieldId,
      callMode: 'update',
      n: field,
      v: fieldValue,
    };

    console.log('prems');
    console.log(prms);
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('item/update_custom_field', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        console.log('newname USER');
        console.log(result);
        resolve(result);
      });
    });
  } catch (e) {
    console.error('updateUserName - ERROR ', e);
  }
}
