import { useReducer, useEffect, useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Store } from '../Store';
import { getResources } from '../backend/customers';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom';
import SearchBar from '../Components/searchBar';
import Customers from '../Components/customers';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FILTER_REQUEST':
      return { ...state, loading: true };
    case 'FILTER_SUCCESS':
      return { ...state, filteredCustomers: action.payload, loading: false };
    case 'FILTER_FAIL':
      return { ...state, error: action.payload };
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, customers: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

function CustomersPage() {
  const navigate = useNavigate();

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  //logger(reducer)
  const [{ loading, error, customers, filteredCustomers }, dispatch] =
    useReducer(reducer, {
      customers: [],
      filteredCustomers: [],
      loading: true,
      error: '',
    });

  const [searchValue, setSearchValue] = useState('');

  const addCustomerHandler = async () => {
    try {
      navigate('/addcustomer');
    } catch (err) {
      //toast.error('Something went wrong. Try again.');
    }
  };

  const updateSearchValue = (searchValue) => {
    try {
      setSearchValue(searchValue);
      dispatch({ type: 'FILTER_REQUEST' });
      let data = customers;
      if (searchValue) {
        data = customers.filter((customer) => {
          return `${customer.ptId.toString()} ${customer.name.toLowerCase()}`.includes(
            searchValue.toLowerCase()
          );
        });
      }
      dispatch({ type: 'FILTER_SUCCESS', payload: data });
    } catch (err) {
      dispatch({ type: 'FILTER_FAIL', payload: error });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        console.log('new cust 4');
        const data = await getResources(userInfo);
        //data = data.sort.... sort data alphabetically
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
        userInfo.signedIn = true; //Do not store in local storage - used for current session
        ctxDispatch({ type: 'USER_SIGNIN', payload: userInfo });
      } catch (e) {
        dispatch({ type: 'FETCH_FAIL', payload: e.message });
      }
    };
    fetchData();
  }, [ctxDispatch, userInfo]);

  return (
    <div>
      <Helmet>
        <title>Pressure Track Admin</title>
      </Helmet>

      <Container>
        <Card>
          <Card.Header>
            <Row>
              <Col xs={2}>
                <h5 className="mt-2">Customers</h5>

                {/* <div>Server Address: {serverData.serverAddress}</div>
          <div>IP Address: {serverData.ipAddress}</div> */}
              </Col>
              <Col xs={3}>
                <SearchBar
                  searchValue={searchValue}
                  onChange={updateSearchValue}
                />
              </Col>
              <Col xs={7} className="text-end">
                <Row>
                  <Col>
                    <Button
                      type="button"
                      variant="outline-dark"
                      onClick={addCustomerHandler}
                    >
                      Add Customer
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            {searchValue ? (
              <Customers customers={filteredCustomers}></Customers>
            ) : (
              <Customers customers={customers}></Customers>
            )}
          </Card.Body>
          <Card.Footer>
            <div>
              page 1
              {/* {[...Array(pages).keys()].map((p) => (
                <Link
                  className={
                    p + 1 === Number(page) ? 'btn text-bold border' : 'btn'
                  }
                  key={p + 1}
                  to={`/unitlist/${userInfo.ptId}?page=${p + 1}`}
                >
                  {p + 1}
                </Link>
              ))} */}
            </div>
          </Card.Footer>
        </Card>
      </Container>

      {/* <div> OLD!!!
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          customers.map((customer) => (
            <Customer key={customer.id} customer={customer}></Customer>
          ))
        )}
      </div> */}
    </div>
  );
}

export default CustomersPage;
