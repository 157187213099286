import React from 'react';

const SearchBar = ({ searchValue, onChange }) => {
  return (
    <input
      className="form-control"
      type="search"
      placeholder="Search..."
      aria-label="Search"
      value={searchValue}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

export default SearchBar;
