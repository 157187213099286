import { useReducer, useEffect } from 'react';
import logger from 'use-reducer-logger';
import Unit from '../Components/unit';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, units: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, error: action.payload };
    case 'ADD_REQUEST':
      return { ...state, loadingAdd: true };
    case 'ADD_SUCCESS':
      return { ...state, units: action.payload, loadingAdd: false };
    case 'ADD_FAIL':
      return { ...state, loadingAdd: false };
    default:
      return state;
  }
};

function UnitsPage() {
  const navigate = useNavigate;

  //const [units, setCustomers] = useState([]);
  const [{ loading, error, units, loadingAdd }, dispatch] = useReducer(
    reducer,
    {
      units: [],
      loading: true,
      error: '',
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const data = await getUnits();
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };
    fetchData();
  }, []);

  async function getUnits() {
    try {
      const session = wialon.core.Session.getInstance();
      const flags =
        wialon.item.Item.dataFlag.base | wialon.item.Unit.dataFlag.lastMessage;
      session.loadLibrary('itemIcon');
      return await new Promise((resolve, reject) => {
        session.updateDataFlags(
          [{ type: 'type', data: 'avl_unit', flags: flags, mode: 1 }],
          (error) => {
            if (error) {
              console.log(
                'tyretrackJS: getUnit - API ERROR ' +
                  error +
                  ' #' +
                  wialon.core.Errors.getErrorText(error)
              );
              reject({ type: 'API_ERROR', error: error });
              return;
            }

            const units = session.getItems('avl_unit');
            if (!units || !units.length) {
              console.log('tyretrackJS: getUnits - No units found');
              return;
            }
            console.log(units);
            const data = [];
            for (let i = 0; i < units.length; i++) {
              const unit = {};
              unit.id = units[i].getId();
              unit.name = units[i].getName();
              data.push(unit);
            }
            resolve(data);
          }
        );
      });
    } catch (e) {
      console.error('tyretrackJS: getResource - ERROR ', e);
    }
  }

  const addUnitHandler = async () => {
    try {
      dispatch({ type: 'ADD_REQUEST' });
      alert('wialon add unit');
      toast.success('Unit created successfully.');
      dispatch({ type: 'ADD_SUCCESS' });
      //navigate('/'); to add unit page
    } catch (err) {
      toast.error('Add unit failed.');
      dispatch({ type: 'ADD_FAIL' });
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <h2>Units</h2>
        </Col>
        <Col className="col text-end"></Col>
      </Row>
      {loadingAdd && <div>Loading...</div>}
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        units.map((unit) => <Unit key={unit.id} unit={unit}></Unit>)
      )}
    </div>
  );
}

export default UnitsPage;
