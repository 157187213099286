import { useContext, useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Store } from '../Store';

import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { addSensor } from '../backend/units';
import PageHeader from '../Components/pageHeader';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'ADD_REQUEST':
      return { ...state, loading: true };
    case 'ADD_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'ADD_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function AddSensorPage() {
  const navigate = useNavigate();

  // const { search } = useLocation();
  // const redirectUrl = new URLSearchParams(search).get('redirect');
  // const redirect = redirectUrl ? redirectUrl : '/customers';

  const params = useParams();
  const { unitid, unitname } = params;

  const { state } = useContext(Store);
  const { userInfo } = state;

  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const [sensorId, setSensorId] = useState('');
  const [name, setName] = useState('');
  const [parameter, setParameter] = useState('');
  const [measurement, setMeasurement] = useState('');
  const [measurements, setMeasurements] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //dispatch({ type: 'FETCH_REQUEST' });
        // const data = [
        //   { id: 1, metric: 'bar' },
        //   { id: 2, metric: 'degreeC' },
        //   { id: 3, metric: 'V' },
        // ];
        const measurements = ['Select...', 'bar', 'degreeC', 'V'];

        //const measurements = [{ id: 0, metric: 'Select...' }, ...data];
        setMeasurements(measurements);
        //dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };
    fetchData();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      console.log(unitid);
      dispatch({ type: 'ADD_REQUEST' });
      const sensor = {};
      sensor.sensorId = sensorId;
      sensor.name = name;
      sensor.parameter = parameter;
      sensor.measurement = measurement;
      sensor.unitId = unitid;
      await addSensor(sensor);

      dispatch({ type: 'ADD_SUCCESS' });
      toast.success('Sensor added successfully.');
      console.log('params id');
      navigate(`/unit/${unitid}/sensors`);
    } catch (err) {
      toast.error(`Failed to add sensor.
      \n'Sensor Id' should be unique.
      \nTry again.`);
      dispatch({ type: 'ADD_FAIL' });
    }
  };

  return (
    <Container className="small-container">
      <PageHeader ptId={userInfo.ptId}></PageHeader>
      <Card>
        <Form onSubmit={submitHandler}>
          <Card.Header>
            {' '}
            <Row>
              <Col>
                Unit<h5>{unitname}</h5>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Row>
              {' '}
              <Col>
                <h5>New Sensor</h5>
              </Col>
              <Col>
                <Button type="submit" className="float-end">
                  Add
                </Button>
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="sensorId">
              <Form.Label>Sensor Id</Form.Label>
              <Form.Control
                value={sensorId}
                onChange={(e) => {
                  setSensorId(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="measurement">
              <Form.Label>Unit of Measurement</Form.Label>
              <Form.Select
                value={measurement}
                onChange={(e) => {
                  console.log('e.target');
                  console.log(e.target.value);
                  setMeasurement(e.target.value);
                }}
                required
              >
                {measurements.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="parameter">
              <Form.Label>Parameter</Form.Label>
              <Form.Control
                value={parameter}
                onChange={(e) => {
                  setParameter(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
          </Card.Body>
        </Form>
      </Card>
    </Container>
  );
}
