import { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from '../Store';
import getUnit from '../backend/getUnit';

import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PageHeader from '../Components/pageHeader';
import Button from 'react-bootstrap/Button';
import {
  updateUnitName,
  updateDeviceInfo,
  updatePhoneNo,
} from '../backend/units';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    default:
      return state;
  }
};

export default function EditUnitPage() {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const navigate = useNavigate();
  const params = useParams();
  const { id: unitId } = params;

  const [{ loading, error, loadingUpdate }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const [unit, setUnit] = useState('');
  const [editUnitName, setEditUnitName] = useState(0);
  const [device, setDevice] = useState('');
  const [devices, setDevices] = useState([]);
  const [imei, setImei] = useState('');
  const [editDeviceInfo, setEditDeviceInfo] = useState(0);
  const [phoneNo, setPhoneNo] = useState('');
  const [editPhoneNo, setEditPhoneNo] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const data = await getUnit(userInfo.tId, unitId);

        setUnit(data.name);
        setDevice(data.deviceType);
        setImei(data.imei);
        setPhoneNo(data.phoneNumber);
        //TheArray.unshift(TheNewObject);
        setDevices(data.devices);

        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };
    fetchData();
  }, [unitId, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'UPDATE_REQUEST' });
      if (editUnitName) await updateUnitName(unitId, unit);
      if (editDeviceInfo) await updateDeviceInfo(unitId, device, imei);
      if (editPhoneNo) await updatePhoneNo(unitId, phoneNo);
      dispatch({ type: 'UPDATE_SUCCESS' });
      toast.success('Unit updated successfully.');
      navigate(`/unitlist/${userInfo.ptId}`);
    } catch (err) {
      toast.error(
        `Update failed.
        \n'Unit Name', 'IMEI' and 'Phone No' should be unique.
        \nTry again.`
      );
      dispatch({ type: 'UPDATE_FAIL' });
    }
  };

  return (
    <Container className="small-container">
      <PageHeader ptId={userInfo.ptId}></PageHeader>

      <Card>
        <Card.Body>
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>{error}</div>
          ) : (
            <Form onSubmit={submitHandler}>
              <Row>
                {' '}
                <Col>
                  <h5>Edit Unit</h5>
                </Col>
                <Col>
                  <Button
                    className="float-end"
                    variant="outline-dark"
                    disabled={loadingUpdate}
                    type="submit"
                  >
                    Update
                  </Button>
                  {loadingUpdate && <div>Loading...</div>}
                </Col>
              </Row>

              <Form.Group className="mb-3" controlId="unit">
                <Form.Label>Unit Name</Form.Label>
                <Form.Control
                  value={unit}
                  onChange={(e) => {
                    setUnit(e.target.value);
                    setEditUnitName(1);
                  }}
                  required
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="device">
                <Form.Label>Device</Form.Label>
                <Form.Select
                  value={device}
                  onChange={(e) => {
                    console.log('e.target');
                    console.log(e.target.value);
                    setDevice(e.target.value);
                    setEditDeviceInfo(1);
                  }}
                  required
                >
                  {devices.map((item) => (
                    <option value={item.id}>{item.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="imei">
                <Form.Label>IMEI</Form.Label>
                <Form.Control
                  value={imei}
                  onChange={(e) => {
                    setImei(e.target.value);
                    setEditDeviceInfo(1);
                  }}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="phoneNo">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  value={phoneNo}
                  onChange={(e) => {
                    setPhoneNo(e.target.value);
                    setEditPhoneNo(1);
                  }}
                  required
                ></Form.Control>
              </Form.Group>
            </Form>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
