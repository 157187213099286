import { useContext, useEffect, useReducer } from 'react';
import getUnits from '../backend/getUnits';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import PageHeader from '../Components/pageHeader';
import { Store } from '../Store';
import { toast } from 'react-toastify';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        serverData: action.payload.serverData,
        units: action.payload.units,
        page: action.payload.page,
        pages: action.payload.pages,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function UnitList() {
  const navigate = useNavigate();

  const { state } = useContext(Store);
  const { userInfo } = state;

  //const [{ loading, error, serverData, units, pages, loadingAdd }, dispatch] =
  const [{ loading, error, units, pages }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const { search } = useLocation();
  const searchPrms = new URLSearchParams(search);
  const page = searchPrms.get('page') || 1;

  const addUnitHandler = async () => {
    try {
      navigate('/addunit');
    } catch (err) {
      toast.error('Something went wrong. Try again.');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const data = await getUnits(userInfo.tId, userInfo.aId, page);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {}
    };
    fetchData();
  }, [userInfo, page]);

  return (
    <div>
      <PageHeader ptId={userInfo.ptId}></PageHeader>
      <Card>
        <Card.Header>
          {' '}
          <Row>
            <Col>
              <h5 className="mt-2">Units</h5>
              {/* <div>Server Address: {serverData.serverAddress}</div>
          <div>IP Address: {serverData.ipAddress}</div> */}
              <div></div>
            </Col>
            <Col className="text-end">
              <div>
                <Button
                  type="button"
                  variant="outline-dark"
                  onClick={addUnitHandler}
                >
                  Add Unit
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {' '}
          <div>
            {loading ? (
              <div>Loading...</div>
            ) : error ? (
              <div>{error}</div>
            ) : (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Unit</th>
                      <th>Date Activated</th>
                      <th>Device Type</th>
                      <th>Type Id</th>
                      <th>TCP Port</th>
                      <th>IMEI</th>
                      <th>Phone No</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {units.map((unit) => (
                      <tr className="align-middle" key={unit.id}>
                        <td>{unit.name}</td>
                        <td>{unit.dateActivated}</td>
                        <td>{unit.deviceType}</td>
                        <td>{unit.deviceTypeId}</td>
                        <td>{unit.tcpPort}</td>
                        <td>{unit.imei}</td>
                        <td>{unit.phoneNumber}</td>
                        <td>
                          <Link to={`/unit/${unit.id}/sensors`}>
                            <Button type="button" variant="outline-secondary">
                              Sensors
                            </Button>
                          </Link>
                        </td>
                        <td>
                          <Link to={`/editunit/${unit.id}`}>
                            <Button type="button" variant="outline-secondary">
                              Edit
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </Card.Body>
        <Card.Footer>
          <div>
            page
            {[...Array(pages).keys()].map((p) => (
              <Link
                className={
                  p + 1 === Number(page) ? 'btn text-bold border' : 'btn'
                }
                key={p + 1}
                to={`/unitlist/${userInfo.ptId}?page=${p + 1}`}
              >
                {p + 1}
              </Link>
            ))}
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
}
