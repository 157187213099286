import { getDeviceInfo, getDevices } from './units.js';

export default async function getUnit(tokenId, unitId) {
  try {
    const serverData = await getServerData(tokenId);
    console.log('serverData');
    console.log(serverData);

    const remote = wialon.core.Remote.getInstance();
    const prms = {
      spec: {
        itemsType: 'avl_unit',
        //propName: 'sys_id, sys_unique_id, sys_phone_number',
        propName: 'sys_id',
        propValueMask: `${unitId}`,
        sortType: 'sys_id',
      },
      force: 1,
      flags: -1,
      from: 0,
      to: 0,
    };

    return new Promise((resolve, reject) => {
      remote.remoteCall('core/search_items', prms, async (error, result) => {
        if (error) {
          console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
          reject({ type: 'API_ERROR', code: error, fullError: result });
        }

        //const data = [];
        const unit = {};

        unit.name = result.items[0].nm;

        unit.deviceTypeId = result.items[0].hw;
        const deviceInfo = await getDeviceInfo(result.items[0].hw);
        const devices = await getDevices();
        console.log('deviceInfo');
        console.log(deviceInfo);
        unit.deviceType = deviceInfo[0].id;
        unit.tcpPort = deviceInfo[0].tp;

        unit.imei = result.items[0].uid;
        unit.phoneNumber = result.items[0].ph;
        unit.sensors = result.items[0].sens;
        unit.devices = devices;

        //data['unit'] = unit;

        console.log('data');
        console.log(unit);
        resolve(unit);
      });
    });
  } catch (e) {
    console.error('tyretrackJS: getUnit - ERROR ', e);
  }
}

async function getServerData(tokenId) {
  const remote = wialon.core.Remote.getInstance();
  const prms = {
    token: tokenId,
    fl: -1,
  };
  return new Promise((resolve, reject) => {
    remote.remoteCall('token/login', prms, (error, result) => {
      if (error) {
        console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
        reject({ type: 'API_ERROR', code: error, fullError: result });
      }
      const serverData = {};

      serverData.ipAddress = result.hw_gw_ip;
      serverData.serverAddress = result.hw_gw_dns;
      console.log('unit result');
      console.log(result);

      resolve(serverData);
    });
  });
}
