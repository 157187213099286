import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function PageHeader(props) {
  const navigate = useNavigate();

  const { ptId } = props;
  const viewAccountInfoHandler = async () => {
    try {
      navigate(`/customer`); //
    } catch (err) {
      //toast.error('Something went wrong. Try again.');
    }
  };
  const viewUsersHandler = async () => {
    try {
      navigate(`/users`); //
    } catch (err) {
      //toast.error('Something went wrong. Try again.');
    }
  };
  const viewUnitsHandler = async () => {
    try {
      navigate(`/unitlist/${ptId}`); //
    } catch (err) {
      //toast.error('Something went wrong. Try again.');
    }
  };

  return (
    <Card className="mb-1">
      <Card.Body>
        <Row>
          <Col>
            Customer<h5>{ptId}</h5>
          </Col>
          <Col>
            <div className="float-end mt-2">
              <Button
                className="me-2"
                variant="secondary"
                type="button"
                onClick={viewAccountInfoHandler}
              >
                Account Info
              </Button>
              <Button
                className="me-2"
                variant="secondary"
                type="button"
                onClick={viewUsersHandler}
              >
                Contacts
              </Button>
              <Button
                type="button"
                variant="secondary"
                onClick={viewUnitsHandler}
              >
                Units
              </Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default PageHeader;
