export async function getUnitSensorData(unitId) {
  try {
    console.log('unitId');
    console.log(unitId);

    const remote = wialon.core.Remote.getInstance();
    const prms = {
      spec: {
        itemsType: 'avl_unit',
        //propName: 'sys_id, sys_unique_id, sys_phone_number',
        propName: 'sys_id',
        propValueMask: `${unitId}`,
        sortType: 'sys_id',
      },
      force: 1,
      flags: -1,
      from: 0,
      to: 0,
    };

    return new Promise((resolve, reject) => {
      remote.remoteCall('core/search_items', prms, async (error, result) => {
        if (error) {
          console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
          reject({ type: 'API_ERROR', code: error, fullError: result });
        }
        const unit = {};

        unit.name = result.items[0].nm;

        const uSensors = [];
        const sensors = result.items[0].sens;

        Object.keys(sensors).forEach((key) =>
          uSensors.push({ s: sensors[key] })
        );

        console.log('uSensors');
        console.log(uSensors);

        const unitSensors = [];
        for (let us = 0; us < uSensors.length; us++) {
          const unitSensor = {};
          unitSensor.name = uSensors[us].s.n;
          unitSensor.description = uSensors[us].s.d;
          unitSensor.measurement = uSensors[us].s.m;
          unitSensor.prm = uSensors[us].s.p;
          unitSensors.push(unitSensor);
        }

        console.log('sensor data');
        console.log(unitSensors);
        unit.sensors = unitSensors;
        resolve(unit);
      });
    });
  } catch (e) {
    console.error('tyretrackJS: getUnit - ERROR ', e);
  }
}

export async function getDeviceInfo(deviceTypeId) {
  try {
    // let prms = {
    //   hwId: '600001043',
    //   action: 'get',
    //   itemId: unitId,
    //   fulldata: 1,
    // };
    let prms = {
      filterType: 'id',
      filterValue: [deviceTypeId],
      includeType: true,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('core/get_hw_types', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('tyretrackJS: getUnit - ERROR ', e);
  }
}

export async function getDevices() {
  try {
    // let prms = {
    //   hwId: '600001043',
    //   action: 'get',
    //   itemId: unitId,
    //   fulldata: 1,
    // };
    let prms = {
      filterType: 'name',
      filterValue: [
        'Teltonika FMC640',
        'Teltonika FMC650',
        'Totem Tech - AT09',
      ],
      includeType: true,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('core/get_hw_types', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }

        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('tyretrackJS: getUnit - ERROR ', e);
  }
}

export async function addUnit(unit) {
  try {
    let prms = {
      creatorId: unit.creatorId,
      name: unit.name,
      hwTypeId: unit.device,
      dataFlags: 1,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('core/create_unit', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }

        updateDeviceInfo(result.item.id, unit.device, unit.imei);
        updatePhoneNo(result.item.id, unit.phoneNo);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('addUnit - ERROR ', e);
  }
}

export async function addSensor(sensor) {
  try {
    let prms = {
      itemId: Number(sensor.unitId),
      id: 0,
      callMode: 'create',
      n: sensor.sensorId,
      d: '',
      c: '',
      m: sensor.measurement,
      p: sensor.parameter,
      t: 'custom',
      f: 0,
      vt: 1,
      vs: 0,
      tbl: [],
    };
    console.log('prms');
    console.log(prms);
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('unit/update_sensor', prms, (error, result) => {
        if (error) {
          console.log('adsensor error');
          console.log(result);
          reject({ type: 'API_ERROR', code: error, fullError: result });

          return;
        }

        console.log('adsensor res');
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('addUnit - ERROR ', e);
  }
}

export async function addSensorPressureTemplate(unitId) {
  try {
    let prms = [];
    for (let i = 1; i < 17; i++) {
      for (let j = 1; j <= 4; j++) {
        // const axle = Math.floor(i / 4);
        // const wheel = (i % 4) + 1;
        // const sensorId =
        //   axle.toString().padStart(2, '0') + wheel.toString() + '0';
        const sensorId = i.toString().padStart(2, '0') + j.toString() + '0';
        console.log('chatgpt');
        console.log(sensorId);
        const prmsObj = {
          svc: 'unit/update_sensor',
          params: {
            itemId: Number(unitId),
            id: 0,
            callMode: 'create',
            n: sensorId,
            d: '',
            c: '',
            m: 'bar',
            p: `${sensorId}_pressure`,
            t: 'custom',
            f: 0,
            vt: 1,
            vs: 0,
            tbl: [],
          },
        };
        prms.push(prmsObj);
      }
    }

    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('core/batch', prms, (error, result) => {
        if (error) {
          console.log('adsensor error');
          console.log(result);
          reject({ type: 'API_ERROR', code: error, fullError: result });

          return;
        }

        console.log('adsensor res');
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('addUnit - ERROR ', e);
  }
}

export async function updateUnitName(unitId, unit) {
  try {
    let prms = {
      itemId: unitId,
      name: unit,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('item/update_name', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        console.log('newname');
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('updateUnitName - ERROR ', e);
  }
}

export async function updatePhoneNo(unitId, phoneNo) {
  try {
    let prms = {
      itemId: unitId,
      phoneNumber: phoneNo,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('unit/update_phone', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        console.log('newphone');
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('updatePhoneNo - ERROR ', e);
  }
}

export async function updateDeviceInfo(unitId, deviceTypeId, imei) {
  try {
    // let prms = {
    //   hwId: '600001043',
    //   action: 'get',
    //   itemId: unitId,
    //   fulldata: 1,
    // };
    console.log('deviceTypeId');
    console.log(deviceTypeId);
    let prms = {
      itemId: unitId,
      deviceTypeId: deviceTypeId,
      uniqueId: imei,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('unit/update_device_type', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('updateDeviceInfo - ERROR ', e);
  }
}

export async function deleteUnit(unitId) {
  try {
    let prms = {
      id: unitId,
    };
    const remote = wialon.core.Remote.getInstance();
    return new Promise((resolve, reject) => {
      remote.remoteCall('item/delete_item', prms, (error, result) => {
        if (error) {
          reject({ type: 'API_ERROR', code: error, fullError: result });
          return;
        }
        console.log('new unit');
        console.log(result);
        resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
      });
    });
  } catch (e) {
    console.error('addUnit - ERROR ', e);
  }
}
