import axios from 'axios';
import { useReducer, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { Helmet } from 'react-helmet-async';
import { Store } from '../Store';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, unit: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default function UnitPage() {
  const { state } = useContext(Store);
  const { userInfo } = state;

  const params = useParams();
  const { id, name } = params;

  const [{ loading, error, unit }, dispatch] = useReducer(reducer, {
    unit: {},
    loading: true,
    error: '',
  });

  async function getServerData() {
    const remote = wialon.core.Remote.getInstance();
    const prms = {
      token: userInfo.tId,
      fl: -1,
    };
    return new Promise((resolve, reject) => {
      remote.remoteCall('token/login', prms, (error, result) => {
        if (error) {
          console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
          reject({ type: 'API_ERROR', code: error, fullError: result });
        }
        const serverData = {};
        // unit.deviceTypeId = result.items[0].hw;
        // unit.deviceType = 'Totem Tech - AT09';
        // unit.serverAddress = 'nl2.gpsgsm.org:20992'; port number
        // unit.imei = result.items[0].uid;
        // unit.phoneNumber = result.items[0].ph;

        serverData.ipAddress = result.hw_gw_ip;
        serverData.serverAddress = result.hw_gw_dns;
        console.log('unit result');
        console.log(result);

        resolve(serverData);
        //resolve(result);
      });
    });
  }

  async function getDeviceInfo(deviceTypeId) {
    try {
      // let prms = {
      //   hwId: '600001043',
      //   action: 'get',
      //   itemId: unitId,
      //   fulldata: 1,
      // };
      let prms = {
        filterType: 'id',
        filterValue: [deviceTypeId],
        includeType: true,
      };
      const remote = wialon.core.Remote.getInstance();
      return new Promise((resolve, reject) => {
        remote.remoteCall('core/get_hw_types', prms, (error, result) => {
          if (error) {
            reject({ type: 'API_ERROR', code: error, fullError: result });
            return;
          }
          resolve(result); //result = id: 600001043; name: "Totem Tech - AT09"
        });
      });
    } catch (e) {
      console.error('tyretrackJS: getUnit - ERROR ', e);
    }
  }

  async function getUnitProperties(unitId) {
    try {
      const serverdata = await getServerData();
      console.log('serverdata');
      console.log(serverdata);

      const unit = {};
      unit.serverAddress = serverdata.serverAddress;
      unit.ipAddress = serverdata.ipAddress;
      const remote = wialon.core.Remote.getInstance();
      const prms = {
        spec: {
          itemsType: 'avl_unit',
          //propName: 'sys_id, sys_unique_id, sys_phone_number',
          propName: 'sys_id',
          propValueMask: `${unitId}`,
          sortType: 'sys_id',
        },
        force: 1,
        flags: -1,
        from: 0,
        to: 0,
      };

      return new Promise((resolve, reject) => {
        remote.remoteCall('core/search_items', prms, async (error, result) => {
          if (error) {
            console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
            reject({ type: 'API_ERROR', code: error, fullError: result });
          }

          unit.dateActivated = result.items[0].ct;

          unit.deviceTypeId = result.items[0].hw;
          const deviceInfo = await getDeviceInfo(result.items[0].hw);
          console.log('deviceInfo');
          console.log(deviceInfo);
          unit.deviceType = deviceInfo[0].name;
          unit.tcpPort = deviceInfo[0].tp;

          unit.imei = result.items[0].uid;
          unit.phoneNumber = result.items[0].ph;
          unit.sensors = result.items[0].sens;

          console.log('result');
          console.log(result);
          console.log(unit);
          resolve(unit);
        });
      });
    } catch (e) {
      console.error('tyretrackJS: getUnit - ERROR ', e);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const data = await getUnitProperties(id);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };
    fetchData();
  }, [id]);

  return loading ? (
    <div>Loading...</div>
  ) : error ? (
    <div>{error}</div>
  ) : (
    <Col>
      <ListGroup variant="flush">
        <ListGroup.Item>
          <Helmet>
            <title>{name}</title>
          </Helmet>
          <h2>{name}</h2>
        </ListGroup.Item>
        <ListGroup.Item>
          <h5>Date Activated</h5>
          {unit.dateActivated}
        </ListGroup.Item>
        <ListGroup.Item>
          <h5>Device Type Id</h5>
          {unit.deviceTypeId}
        </ListGroup.Item>
        <ListGroup.Item>
          <h5>Device Type</h5>
          {unit.deviceType}
        </ListGroup.Item>
        <ListGroup.Item>
          <h5>Server Address</h5>
          {unit.serverAddress}
        </ListGroup.Item>
        <ListGroup.Item>
          <h5>IP Address</h5>
          {unit.ipAddress}
        </ListGroup.Item>
        <ListGroup.Item>
          <h5>TCP Port</h5>
          {unit.tcpPort}
        </ListGroup.Item>
        <ListGroup.Item>
          <h5>IMEI</h5>
          {unit.imei}
        </ListGroup.Item>
        <ListGroup.Item>
          <h5>Phone Number</h5>
          {unit.phoneNumber}
        </ListGroup.Item>
      </ListGroup>
    </Col>
  );
}
