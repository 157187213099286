import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

function Unit(props) {
  const { unit } = props;

  return (
    <Card>
      <Card.Body>
        <Link to={`/unit/${unit.id}/${unit.name}`}>
          <Card.Title>{unit.name} </Card.Title>
        </Link>
        <Card.Text>{unit.id} </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Unit;
