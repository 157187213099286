import { useContext, useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import { addUnit, getDevices } from '../backend/units';
import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import PageHeader from '../Components/pageHeader';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'ADD_REQUEST':
      return { ...state, loading: true };
    case 'ADD_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'ADD_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function AddUnitPage() {
  const navigate = useNavigate();
  // const { search } = useLocation();
  // const redirectUrl = new URLSearchParams(search).get('redirect');
  // const redirect = redirectUrl ? redirectUrl : '/customers';

  const { state } = useContext(Store);
  const { userInfo } = state;

  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const [unitName, setUnitName] = useState('');
  const [device, setDevice] = useState('');
  const [devices, setDevices] = useState([]);
  const [imei, setImei] = useState('');
  const [phoneNo, setPhoneNo] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'ADD_REQUEST' });
      const unit = {};
      unit.creatorId = userInfo.uId;
      unit.name = unitName;
      unit.device = device;
      unit.imei = imei;
      unit.phoneNo = phoneNo;
      await addUnit(unit);
      dispatch({ type: 'ADD_SUCCESS' });
      toast.success('Unit added successfully.');
      navigate(`/unitlist/${userInfo.uId}`);
    } catch (err) {
      toast.error(`Failed to add unit.
      \n'Unit Name', 'IMEI' and 'Phone No' should be unique.
      \nTry again.`);
      dispatch({ type: 'ADD_FAIL' });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const data = await getDevices();
        const devices = [{ id: 0, name: 'Select...' }, ...data];
        console.log('data devices');
        console.log(devices);
        setDevices(devices);

        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };
    fetchData();
  }, []);

  return (
    <Container className="small-container">
      <PageHeader ptId={userInfo.ptId}></PageHeader>
      <Card>
        <Card.Body>
          <Form onSubmit={submitHandler}>
            <Row>
              {' '}
              <Col>
                <h5>New Unit</h5>
              </Col>
              <Col>
                <Button
                  type="submit"
                  variant="outline-secondary"
                  className="float-end"
                >
                  Add
                </Button>
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="unit">
              <Form.Label>Unit</Form.Label>
              <Form.Control
                value={unitName}
                onChange={(e) => {
                  setUnitName(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="device">
              <Form.Label>Device</Form.Label>
              <Form.Select
                value={device}
                onChange={(e) => {
                  console.log('e.target');
                  console.log(e.target.value);
                  setDevice(e.target.value);
                }}
                required
              >
                {devices.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="imei">
              <Form.Label>IMEI</Form.Label>
              <Form.Control
                value={imei}
                onChange={(e) => {
                  setImei(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="phoneNo">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                value={phoneNo}
                onChange={(e) => {
                  setPhoneNo(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}
