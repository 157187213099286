import { useContext } from 'react';
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { Store } from './Store';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import { LinkContainer } from 'react-router-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomersPage from './pages/customersPage';

import CustomerPage from './pages/customerPage';
import UsersPage from './pages/usersPage';
import UnitList from './pages/unitList';
import EditUnitPage from './pages/unitEditPage';
import UnitsPage from './pages/unitsPage';
import UnitPage from './pages/unitPage';
import SignInPage from './pages/signInPage';
import AddUnitPage from './pages/unitAddPage';
import UnitSensorsPage from './pages/unitSensorsPage';
import AddSensorPage from './pages/sensorAddPage';
import EditUserPage from './pages/userEditPage';
import AddUserPage from './pages/userAddPage';
import AddCustomerPage from './pages/customerAddPage';
import HomePage from './pages/homePage';

function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const signoutHandler = () => {
    wialon.core.Session.getInstance().logout(
      // if user exist - logout
      function (error) {
        // logout callback
        if (error)
          console.log(`Error: ${wialon.core.Errors.getErrorText(error)}`);
        // logout failed, print error
        else console.log('Logout success!');
      }
    );
    console.log(`localStorage.removeItem('userInfo')`);
    localStorage.removeItem('userInfo');
    ctxDispatch({ type: 'USER_SIGNOUT' });
  };

  return (
    <BrowserRouter>
      <div className="d-flex flex-column app-container">
        <ToastContainer position="bottom-center" limit={1} />

        <header>
          <Navbar bg="dark" variant="dark">
            <Container>
              {/* {userInfo ? (
                <LinkContainer to="/">
                  <Navbar.Brand>Pressure Track Administrator</Navbar.Brand>
                </LinkContainer>
              ) : (
                <LinkContainer to="/signin">
                  <Navbar.Brand>Pressure Track Administrator</Navbar.Brand>
                </LinkContainer>
              )} */}

              <LinkContainer to="/">
                <Navbar.Brand>Pressure Track Administrator</Navbar.Brand>
              </LinkContainer>

              <Nav className="me-auto">
                <Link className="nav-link" to="/customers">
                  All Customers
                </Link>
                <Link className="nav-link" to="/units">
                  All Units
                </Link>

                {userInfo ? (
                  <NavDropdown id="basic-nav-dropdown" title={userInfo.user}>
                    <LinkContainer to="/profile">
                      <NavDropdown.Item>User Profile</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to="/">
                      <NavDropdown.Item onClick={signoutHandler}>
                        Sign Out
                      </NavDropdown.Item>
                    </LinkContainer>
                  </NavDropdown>
                ) : (
                  <Link className="nav-link" to="/signin">
                    Sign In
                  </Link>
                )}

                {/* <div class="collapse navbar-collapse" id="nav-main">
                  <ul class="navbar-nav me-auto">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        aria-current="page"
                        href="#customers"
                      >
                        Customers <span class="sr-only"></span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#units">
                        Units
                      </a>
                    </li>
                    <li class="nav-item dropdown">
                      <a
                        class="nav-link dropdown-toggle"
                        href="/"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Devices
                      </a>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <a class="dropdown-item" href="#faulty">
                          Faulty
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#repaired">
                          Repaired
                        </a>
                      </div>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#sensors">
                        Sensors
                      </a>
                    </li>
                  </ul>

                </div> */}
              </Nav>
            </Container>
          </Navbar>
        </header>
        <main>
          <Container className="mt-3">
            <Routes>
              <Route path="/unit/:id/:name" element={<UnitPage />} />
              <Route path="/units" element={<UnitsPage />} />
              <Route path="/unitlist/:ptid" element={<UnitList />} />
              <Route path="/unit/:id/sensors" element={<UnitSensorsPage />} />
              <Route path="/editunit/:id" element={<EditUnitPage />} />
              <Route path="/addunit" element={<AddUnitPage />} />
              <Route
                path="/addsensor/:unitid/:unitname"
                element={<AddSensorPage />}
              />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/edituser/:id" element={<EditUserPage />} />
              <Route path="/adduser" element={<AddUserPage />} />
              <Route path="/addcustomer" element={<AddCustomerPage />} />
              <Route path="/customer" element={<CustomerPage />} />
              {/* {userInfo ? (
                <Route path="/" element={<CustomersPage />} />
              ) : (
                <Route path="/signin" element={<SignInPage />} />
              )} */}
              <Route path="/customers" element={<CustomersPage />} />

              <Route path="/signin" element={<SignInPage />} />
              <Route path="/" element={<HomePage />} />
            </Routes>
          </Container>
        </main>
        <footer>
          <div className="text-center">version 1.0.5</div>
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;
