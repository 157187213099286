import { getDeviceInfo } from './units.js';

export default async function getUnits(tokenId, customerId, page) {
  try {
    const serverData = await getServerData(tokenId);
    console.log('serverData');
    console.log(serverData);

    const remote = wialon.core.Remote.getInstance();
    const prms = {
      spec: {
        itemsType: 'avl_unit',
        //propName: 'sys_id, sys_unique_id, sys_phone_number',
        propName: 'sys_billing_account_guid',
        propValueMask: `${customerId}`,
        // propValueMask: `${unitId}`,21982526
        sortType: 'sys_name',
      },
      force: 1,
      flags: -1,
      from: 0,
      to: 0,
    };

    return new Promise((resolve, reject) => {
      remote.remoteCall('core/search_items', prms, async (error, result) => {
        if (error) {
          console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
          reject({ type: 'API_ERROR', code: error, fullError: result });
        }

        const data = [];
        const units = [];
        let deviceInfo = {};
        let prevDeviceTypeId = 0;

        const fromIndex = page * 10 - 10;
        let toIndex = page * 10;
        if (toIndex > result.items.length) toIndex = result.items.length;

        //for (let u = 0; u < result.items.length; u++) {
        for (let u = fromIndex; u < toIndex; u++) {
          const unit = {};

          console.log(`API {JSON.stringify(result)}`);
          console.log(result.items[u]);
          unit.id = result.items[u].id;
          unit.name = result.items[u].nm;
          // unit.serverAddress = unitdata.serverAddress;
          // unit.ipAddress = unitdata.ipAddress;

          unit.dateActivated = result.items[u].ct;

          const deviceTypeId = result.items[u].hw;
          unit.deviceTypeId = deviceTypeId;
          if (deviceTypeId !== prevDeviceTypeId) {
            prevDeviceTypeId = deviceTypeId;
            deviceInfo = await getDeviceInfo(deviceTypeId);
          }
          unit.deviceType = deviceInfo[0].name;
          unit.tcpPort = deviceInfo[0].tp;

          unit.imei = result.items[u].uid;
          unit.phoneNumber = result.items[u].ph;
          unit.sensors = result.items[u].sens;

          unit.page = Math.floor(result.items.length + 1 / 10) + 1;
          units.push(unit);
        }
        data['serverData'] = serverData;
        data['units'] = units;
        data['unitCount'] = result.items.length;
        data['pages'] = Math.ceil(result.items.length / 10);

        console.log('data');
        console.log(data);
        resolve(data);
      });
    });
  } catch (e) {
    console.error('tyretrackJS: getUnit - ERROR ', e);
  }
}

async function getServerData(tokenId) {
  const remote = wialon.core.Remote.getInstance();
  const prms = {
    token: tokenId,
    fl: -1,
  };
  return new Promise((resolve, reject) => {
    remote.remoteCall('token/login', prms, (error, result) => {
      if (error) {
        console.log(`API_ERROR ${error} ${JSON.stringify(result)}`);
        reject({ type: 'API_ERROR', code: error, fullError: result });
      }
      const serverData = {};

      serverData.ipAddress = result.hw_gw_ip;
      serverData.serverAddress = result.hw_gw_dns;
      console.log('unit result');
      console.log(result);

      resolve(serverData);
    });
  });
}
