import { Link, useNavigate } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function User(props) {
  const navigate = useNavigate();

  const { user } = props;

  const editUser = async () => {
    console.log(`/edituser/${user.userId}`);
    navigate(`/edituser/${user.userId}`);
  };
  /* <Link className="text-decoration-none" to={``}></Link> */

  return (
    <Card>
      {/* <img src={user.image} className="card-img-top" alt="Foto " /> */}

      <Card.Body>
        <Card.Title>{user.ptId}</Card.Title>

        <Card.Text>
          <div>
            Name: {user.name} {user.surname}{' '}
          </div>
          <div>Contact No: {user.contactNo} </div>
          <div>e-Mail: {user.emailAddress} </div>
          <div>Role: {user.role} </div>
        </Card.Text>
        <Button
          variant="outline-secondary"
          onClick={() => editUser(user.userId)}
        >
          Edit
        </Button>
      </Card.Body>
    </Card>
  );
}

export default User;
