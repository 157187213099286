import { useContext, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import Card from 'react-bootstrap/Card';
import { tokenHandler } from '../backend/customers';

export default function SignInPage() {
  const navigate = useNavigate();

  // const { search } = useLocation();
  // const redirectInUrl = new URLSearchParams(search).get('redirect');
  // const redirect = redirectInUrl ? redirectInUrl : '/signin';

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  console.log('userInfo');
  console.log(userInfo);

  const submitHandler = async (ev) => {
    try {
      //ev.preventDefault(); to NOT refresh page....
      window.onmessage = async (e) => {
        const msg = e.data;
        if (typeof msg == 'string' && msg.indexOf('access_token=') >= 0) {
          const token = msg.replace('access_token=', '');

          if (token) {
            const userInfo = {};
            userInfo.tId = token;
            userInfo.signedIn = false;

            const signedIn = await tokenHandler(userInfo);

            if (signedIn) {
              localStorage.setItem('userInfo', JSON.stringify(userInfo));
              ctxDispatch({ type: 'USER_SIGNIN', payload: userInfo });

              navigate('/customers');
            }
          }
        }
      };
    } catch (e) {
      toast.error('Invalid Session Id. Sign In.');
    }
  };

  useEffect(() => {
    // let pageRefresh = false;
    // if (!pageRefresh) {
    //   pageRefresh = true;
    //   window.location.reload();
    // }
  }, []);

  return (
    <Container className="small-container">
      <Helmet>
        <title>Sign In</title>
      </Helmet>
      {/* <Card>
        <Card.Body> */}
      <iframe
        title="Sign In"
        className="login-container"
        src="https://hosting.wialon.com/login.html?client_id=PressureTrack&access_type=-1&activation_time=0&duration=2592000&flags=0x1&redirect_uri=https://hosting.wialon.com/post_token.html"
        seamless
        onLoad={submitHandler}
      ></iframe>
      {/* </Card.Body>
      </Card> */}
    </Container>
  );
}
