import { useContext, useEffect, useReducer } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import PageHeader from '../Components/pageHeader';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getUsers } from '../backend/customers';
import User from '../Components/user';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default function UsersPage() {
  const navigate = useNavigate();

  const { state } = useContext(Store);
  const { userInfo } = state;

  //const [{ loading, error, serverData, users, pages, loadingAdd }, dispatch] =
  const [{ loading, error, users }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const { search } = useLocation();
  const searchPrms = new URLSearchParams(search);
  const page = searchPrms.get('page') || 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const data = await getUsers(userInfo);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {}
    };
    fetchData();
  }, [userInfo, page]);

  const addUserHandler = async () => {
    try {
      navigate('/adduser');
    } catch (err) {
      toast.error('Something went wrong. Try again.');
    }
  };

  return (
    <div>
      <PageHeader ptId={userInfo.ptId}></PageHeader>
      <Card>
        <Card.Header>
          {' '}
          <Row>
            <Col>
              <h5 className="mt-2">Contacts</h5>
              {/* <div>Server Address: {serverData.serverAddress}</div>
          <div>IP Address: {serverData.ipAddress}</div> */}
              <div></div>
            </Col>
            <Col className="text-end">
              <div>
                <Button
                  type="button"
                  variant="outline-dark"
                  onClick={addUserHandler}
                >
                  Add Contact
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {' '}
          <div>
            {loading ? (
              <div>Loading...</div>
            ) : error ? (
              <div>{error}</div>
            ) : (
              <Row>
                {users.map((user) => (
                  <Col key={user.userId} sm={6} md={4} lg={3} className="mb-3">
                    <User user={user}></User>
                  </Col>
                ))}
              </Row>
            )}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
}
