import { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from '../Store';
import {
  addUser,
  getUser,
  updateUser,
  updateUserCustomFields,
  updateUserCustomProperties,
} from '../backend/customers';

import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PageHeader from '../Components/pageHeader';
import Button from 'react-bootstrap/Button';
// import {
//   updateUserName,
//   updateDeviceInfo,
//   updatePhoneNo,
// } from '../backend/users';

const reducer = (state, action) => {
  switch (action.type) {
    case 'ADD_REQUEST':
      return { ...state, loading: true };
    case 'ADD_SUCCESS':
      return { ...state, loading: false };
    case 'ADD_FAIL':
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default function AddUserPage() {
  const navigate = useNavigate();

  const { state } = useContext(Store);
  const { userInfo } = state;

  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: false,
    error: '',
  });

  const [user, setUser] = useState('');
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [contactNo, setContactNo] = useState('');
  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'ADD_REQUEST' });
      const newUser = {};
      newUser.user = user;
      newUser.name = name;
      newUser.surname = surname;
      newUser.contactNo = contactNo;
      newUser.email = email;
      newUser.role = role;
      await addUser(userInfo.uId, newUser);

      // if (editName)
      //   await updateUserCustomFields(userId, nameFieldId, 'Name', name);
      // if (editSurname)
      //   await updateUserCustomFields(
      //     userId,
      //     surnameFieldId,
      //     'Surname',
      //     surname
      //   );
      // if (editContactNo)
      //   await updateUserCustomFields(
      //     userId,
      //     contactNoFieldId,
      //     'ContactNo',
      //     contactNo
      //   );
      // if (editEmail) await updateUserCustomProperties(userId, 'email', email);
      // if (editRole)
      //   await updateUserCustomFields(userId, roleFieldId, 'Role', role);
      dispatch({ type: 'ADD_SUCCESS' });
      toast.success('Contact added successfully.');
      navigate(`/users`);
    } catch (e) {
      dispatch({ type: 'ADD_FAIL' });
      toast.error(
        `Add failed.\n Try again.\n
        ${e.fullError.reason}.`
      );
    }
  };

  return (
    <Container className="small-container">
      <PageHeader ptId={userInfo.ptId}></PageHeader>

      <Card>
        <Card.Body>
          <Form onSubmit={submitHandler}>
            <Row>
              {' '}
              <Col>
                <h5>Add Contact</h5>
              </Col>
              <Col>
                <Button
                  className="float-end"
                  variant="outline-dark"
                  disabled={loading}
                  type="submit"
                >
                  Add
                </Button>
                {loading && <div>Loading...</div>}
              </Col>
            </Row>

            <Form.Group className="mb-3" controlId="user">
              <Form.Label>Username</Form.Label>
              <Form.Control
                value={user}
                onChange={(e) => {
                  setUser(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>

            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="surname">
              <Form.Label>Surname</Form.Label>
              <Form.Control
                value={surname}
                onChange={(e) => {
                  setSurname(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="contactNo">
              <Form.Label>Contact Number</Form.Label>
              <Form.Control
                value={contactNo}
                onChange={(e) => {
                  setContactNo(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>e-Mail Address</Form.Label>
              <Form.Control
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
            <Form.Group className="mb-3" controlId="role">
              <Form.Label>Role</Form.Label>
              <Form.Control
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
                required
              ></Form.Control>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
}
