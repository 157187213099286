import { useReducer, useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import PageHeader from '../Components/pageHeader';

import { Store } from '../Store';

import {
  getResource,
  manageAccount,
  updateBillingPlan,
} from '../backend/customers';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, customer: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, error: action.payload };
    case 'UPDATE_ACCOUNTSTATUS_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_ACCOUNTSTATUS_SUCCESS':
      return {
        ...state,
        customer: action.payload,
        loadingUpdate: false,
      };
    case 'UPDATE_ACCOUNTSTATUS_FAIL':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_BILLINGPLAN_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_BILLINGPLAN_SUCCESS':
      return {
        ...state,
        customer: action.payload,
        loadingUpdate: false,
      };
    case 'UPDATE_BILLINGPLAN_FAIL':
      return { ...state, loadingUpdate: false };
    default:
      return state;
  }
};

export default function CustomerPage() {
  const navigate = useNavigate();

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;
  console.log('userInfo');
  console.log(userInfo);

  const [billingPlan, setBillingPlan] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [billingPlans, setBillingPlans] = useState([]);
  const [accountStatuses, setAccountStatuses] = useState([]);

  const params = useParams();
  const { id, userid: userId, ptid: ptId } = params;

  const [{ loading, error, customer, loadingUpdate }, dispatch] = useReducer(
    reducer,
    {
      customer: {},
      loading: true,
      error: '',
      loadingUpdate: false,
    }
  );

  const editAccountStatusHandler = async (accountStatus) => {
    try {
      if (accountStatus !== 'Edit...') {
        dispatch({ type: 'UPDATE_ACCOUNTSTATUS_REQUEST' });

        console.log(`accountstatus ${accountStatus}`);
        const error = await manageAccount(
          userInfo.aId,
          accountStatus === 'Enabled' ? true : false
        );
        if (!error) {
          const c = {};
          c.name = customer.name;
          c.accountStatus = accountStatus;
          c.accountPlan = customer.accountPlan;
          c.accountBalance = customer.accountBalance;
          dispatch({
            type: 'UPDATE_ACCOUNTSTATUS_SUCCESS',
            payload: c,
          });
          toast.success(`'Account Status' updated successfully.`);
        } else {
          dispatch({
            type: 'UPDATE_ACCOUNTSTATUS_FAIL',
          });
          toast.error(`${error}. Update failed.`);
        }
      }
    } catch (e) {
      dispatch({
        type: 'UPDATE_ACCOUNTSTATUS_FAIL',
        payload: e,
      });
      toast.error(`${e}. Update failed.`);
    }
  };

  const editBillingPlanHandler = async (billingPlan) => {
    try {
      if (accountStatus !== 'Edit...') {
        dispatch({ type: 'UPDATE_BILLINGPLAN_REQUEST' });

        console.log(`accountstatus ${billingPlan}`);
        const error = await updateBillingPlan(userInfo.aId, billingPlan);
        console.log(`result ${error}`);

        if (!error) {
          const c = {};
          c.name = customer.name;
          c.accountStatus = customer.accountStatus;
          c.accountPlan = billingPlan;
          c.accountBalance = customer.accountBalance;
          dispatch({
            type: 'UPDATE_BILLINGPLAN_SUCCESS',
            payload: c,
          });
          toast.success(`'Billing Plan' updated successfully.`);
        } else {
          dispatch({
            type: 'UPDATE_BILLINGPLAN_FAIL',
          });
          toast.error(`${error}. Update failed.`);
        }
      }
    } catch (e) {
      dispatch({
        type: 'UPDATE_BILLINGPLAN_FAIL',
        payload: e,
      });
      toast.error(`${e}. Update failed.`);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });

        const selectBillingPlans = [
          'Edit...',
          'pressuretrackglobal',
          'pressuretrack new plan',
        ];

        setBillingPlans(selectBillingPlans);

        const selectAccountStatuses = ['Edit...', 'Enabled', 'BLOCKED'];
        setAccountStatuses(selectAccountStatuses);

        const data = await getResource(userInfo.aId);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };
    fetchData();
  }, [ctxDispatch, id, ptId, userId, userInfo]);

  return (
    <Container>
      <PageHeader ptId={userInfo.ptId}></PageHeader>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <Card>
          <Card.Body>
            <Col>
              <ListGroup variant="flush">
                <ListGroup.Item></ListGroup.Item>
                <ListGroup.Item>
                  Account Name <h5>{customer.name}</h5>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col sm={3}>
                      Status<h5>{customer.accountStatus}</h5>
                    </Col>
                    <Col sm={9}>
                      <Form.Select
                        size="lg"
                        value={accountStatus}
                        onChange={(e) => {
                          setAccountStatus(e.target.value);
                          editAccountStatusHandler(e.target.value);
                        }}
                        required
                      >
                        {accountStatuses.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col sm={3}>
                      Billing Plan<h5> {customer.accountPlan}</h5>
                    </Col>
                    <Col sm={9}>
                      <Form.Select
                        size="lg"
                        value={billingPlan}
                        onChange={(e) => {
                          setBillingPlan(e.target.value);
                          editBillingPlanHandler(e.target.value);
                        }}
                        required
                      >
                        {billingPlans.map((item) => (
                          <option key={item} value={item}>
                            {item}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  Balance<h5> {customer.accountBalance}</h5>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Card.Body>
        </Card>
      )}
    </Container>
  );
}
