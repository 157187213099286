import { useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Store } from '../Store';
import {
  createUserCustomProp,
  getUser,
  updateUser,
  updateUserCustomFields,
  updateUserCustomProperties,
} from '../backend/customers';

import { toast } from 'react-toastify';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PageHeader from '../Components/pageHeader';
import Button from 'react-bootstrap/Button';
// import {
//   updateUserName,
//   updateDeviceInfo,
//   updatePhoneNo,
// } from '../backend/users';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    default:
      return state;
  }
};

export default function EditUserPage() {
  const navigate = useNavigate();

  const { state } = useContext(Store);
  const { userInfo } = state;

  const params = useParams();
  const { id: userId } = params;

  const [{ loading, error, loadingUpdate }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  const [editUser, setEditUser] = useState(0);
  const [user, setUser] = useState('');

  const [editName, setEditName] = useState(0);
  const [nameFieldId, setNameFieldId] = useState(0);
  const [name, setName] = useState('');

  const [editSurname, setEditSurname] = useState(0);
  const [surnameFieldId, setSurnameFieldId] = useState(0);
  const [surname, setSurname] = useState('');

  const [editContactNo, setEditContactNo] = useState(0);
  const [contactNoFieldId, setContactNoFieldId] = useState(0);
  const [contactNo, setContactNo] = useState('');

  const [editEmail, setEditEmail] = useState(0);
  const [email, setEmail] = useState('');

  const [editRole, setEditRole] = useState(0);
  const [roleFieldId, setRoleFieldId] = useState(0);
  const [role, setRole] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        //const data = await getUser(userInfo.ptId, userId);
        const data = await getUser(userId);

        setUser(data.ptId);

        setNameFieldId(data.nameFieldId);
        setName(data.name);

        setSurnameFieldId(data.surnameFieldId);
        setSurname(data.surname);

        setContactNoFieldId(data.contactNoFieldId);
        setContactNo(data.contactNo);

        setEmail(data.email);

        setRoleFieldId(data.roleFieldId);
        setRole(data.role);

        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };
    fetchData();
  }, [userId, userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'UPDATE_REQUEST' });
      if (editUser) await updateUser(userId, user);

      if (editName)
        await updateUserCustomFields(userId, nameFieldId, 'Name', name);
      if (editSurname)
        await updateUserCustomFields(
          userId,
          surnameFieldId,
          'Surname',
          surname
        );
      if (editContactNo)
        await updateUserCustomFields(
          userId,
          contactNoFieldId,
          'ContactNo',
          contactNo
        );
      if (editEmail) await updateUserCustomProperties(userId, 'email', email);
      if (editRole)
        await updateUserCustomFields(userId, roleFieldId, 'Role', role);
      dispatch({ type: 'UPDATE_SUCCESS' });
      toast.success('Contact updated successfully.');
      navigate(`/users`);
    } catch (e) {
      toast.error(
        `Add failed.\n Try again.\n
        ${e.fullError.reason}.`
      );
      dispatch({ type: 'UPDATE_FAIL' });
    }
  };

  return (
    <Container className="small-container">
      <PageHeader ptId={userInfo.ptId}></PageHeader>

      <Card>
        <Card.Body>
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>{error}</div>
          ) : (
            <Form onSubmit={submitHandler}>
              <Row>
                {' '}
                <Col>
                  <h5>Edit Contact</h5>
                </Col>
                <Col>
                  <Button
                    className="float-end"
                    variant="outline-secondary"
                    disabled={loadingUpdate}
                    type="submit"
                  >
                    Update
                  </Button>
                  {loadingUpdate && <div>Loading...</div>}
                </Col>
              </Row>

              <Form.Group className="mb-3" controlId="user">
                <Form.Label>Contact</Form.Label>
                <Form.Control
                  value={user}
                  onChange={(e) => {
                    setUser(e.target.value);
                    setEditUser(1);
                  }}
                  required
                ></Form.Control>
              </Form.Group>

              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setEditName(1);
                  }}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="surname">
                <Form.Label>Surname</Form.Label>
                <Form.Control
                  value={surname}
                  onChange={(e) => {
                    setSurname(e.target.value);
                    setEditSurname(1);
                  }}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="contactNo">
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  value={contactNo}
                  onChange={(e) => {
                    setContactNo(e.target.value);
                    setEditContactNo(1);
                  }}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>e-Mail Address</Form.Label>
                <Form.Control
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEditEmail(1);
                  }}
                  required
                ></Form.Control>
              </Form.Group>
              <Form.Group className="mb-3" controlId="role">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  value={role}
                  onChange={(e) => {
                    setRole(e.target.value);
                    setEditRole(1);
                  }}
                  required
                ></Form.Control>
              </Form.Group>
            </Form>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
}
