import { useContext, useEffect, useReducer } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { addSensorPressureTemplate, getUnitSensorData } from '../backend/units';
import PageHeader from '../Components/pageHeader';
import { Store } from '../Store';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      console.log('action.payload.sensors');
      console.log(action.payload.sensors);
      return {
        ...state,
        unitName: action.payload.name,
        sensors: action.payload.sensors,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default function UnitSensorsPage() {
  const navigate = useNavigate();

  const { state } = useContext(Store);
  const { userInfo } = state;

  const params = useParams();
  const { id: unitId } = params;
  console.log('params');
  console.log(params);

  const [{ loading, error, unitName, sensors, pages }, dispatch] = useReducer(
    reducer,
    {
      loading: true,
      error: '',
    }
  );

  const { search } = useLocation();
  const searchPrms = new URLSearchParams(search);
  const page = searchPrms.get('page') || 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const data = await getUnitSensorData(unitId);
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (err) {
        dispatch({ type: 'FETCH_FAIL', payload: err.message });
      }
    };
    fetchData();
  }, [unitId]);

  const refreshData = async () => {
    try {
      dispatch({ type: 'FETCH_REQUEST' });
      const data = await getUnitSensorData(unitId);
      dispatch({ type: 'FETCH_SUCCESS', payload: data });
    } catch (err) {
      dispatch({ type: 'FETCH_FAIL', payload: err.message });
    }
  };

  const sensorsTemplateHandler = async () => {
    try {
      await addSensorPressureTemplate(unitId);
      refreshData();
      toast.success('Sensors added successfully.');
    } catch (err) {
      toast.error('Add sensor failed. Try again.');
    }
  };

  const addSensorHandler = async () => {
    try {
      navigate(`/addsensor/${unitId}/${unitName}`);
    } catch (err) {
      toast.error('Add sensor failed. Try again.');
    }
  };

  return (
    <div>
      <PageHeader ptId={userInfo.ptId}></PageHeader>
      <Card>
        <Card.Header>
          {' '}
          <Row>
            <Col>
              Unit<h5>{unitName}</h5>
            </Col>

            <Col className="text-end mt-2">
              <div>
                <Button
                  type="button"
                  variant="outline-dark"
                  className="me-2"
                  onClick={addSensorHandler}
                >
                  Add Sensor
                </Button>
                <Button
                  type="button"
                  variant="outline-dark"
                  onClick={sensorsTemplateHandler}
                >
                  Use Template
                </Button>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {' '}
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>{error}</div>
          ) : (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th>Sensor Id</th>
                    <th>Sensor Name</th>
                    <th>Measurement</th>
                    <th>Input Parameter</th>
                    <th>Date Activated</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {sensors.map((sensor) => (
                    <tr key={sensor.name}>
                      <td>{sensor.name}</td>
                      <td>{sensor.description}</td>
                      <td>{sensor.measurement}</td>
                      <td>{sensor.prm}</td>
                      <td>date activated</td>

                      {/* <td>
                    <Link to={`/editunit/${unit.id}`}>
                      <Button type="button" variant="light">
                        Edit
                      </Button>
                    </Link>
                  </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}{' '}
        </Card.Body>
        <Card.Footer>
          <div>
            page
            {[...Array(pages).keys()].map((p) => (
              <Link
                className={
                  p + 1 === Number(page) ? 'btn text-bold border' : 'btn'
                }
                key={p + 1}
                to={`/unitlist/${userInfo.ptId}?page=${p + 1}`}
              >
                {p + 1}
              </Link>
            ))}
          </div>
        </Card.Footer>
      </Card>
    </div>
  );
}
