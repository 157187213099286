import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import splash from '../images/pressuretracksplash.png';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'UPDATE_REQUEST':
      return { ...state, loadingUpdate: true };
    case 'UPDATE_SUCCESS':
      return { ...state, loadingUpdate: false };
    case 'UPDATE_FAIL':
      return { ...state, loadingUpdate: false };
    default:
      return state;
  }
};

export default function HomePage() {
  return (
    <Container className="small-container">
      <Card>
        <img
          style={{ width: '65%', height: '65%' }}
          src={splash}
          className="mx-auto"
          alt="Foto "
        />
      </Card>
    </Container>
  );
}
