import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { Store } from '../Store';

function Customers(props) {
  const { customers } = props;

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo } = state;

  const userInfoHandler = async (user) => {
    console.log(`userHandler ${JSON.stringify(user)}`);

    userInfo.aId = user.id;
    userInfo.uId = user.userId;
    userInfo.ptId = user.ptId;
    userInfo.signedIn = false;
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
    userInfo.signedIn = true; //Do not store in local storage - used for sign in during session
    ctxDispatch({ type: 'USER_SIGNIN', payload: userInfo });

    //dispatch({ type: 'FETCH_REQUEST' });
    //const data = await getResource(id);
    //dispatch({ type: 'FETCH_SUCCESS', payload: data });
  };

  return (
    <div>
      <>
        <table className="table">
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              {/* <th>Status</th>
              <th>Plan</th>
              <th>Balance</th> */}
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer) => (
              <tr
                className="align-middle"
                key={customer.id}
                onClick={() => userInfoHandler(customer)}
              >
                <td>{customer.ptId}</td>
                <td>{customer.name}</td>
                {/* <td>{customer.accountStatus}</td>
                <td>{customer.accountPlan}</td>
                <td>{customer.accountBalance}</td> */}
                <td>
                  <Link to={`/customer`}>
                    <Button type="button" variant="outline-secondary">
                      Account Info
                    </Button>
                  </Link>
                </td>
                <td>
                  <Link to={`/users`}>
                    <Button type="button" variant="outline-secondary">
                      Contacts
                    </Button>
                  </Link>
                </td>
                <td>
                  <Link to={`/unitlist/${customer.ptId}`}>
                    <Button type="button" variant="outline-secondary">
                      Units
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    </div>
  );
}

export default Customers;
